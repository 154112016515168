import WeblinkHeader from "../components/WebLinkHeader"
import { DeviceDel, FirmwareVersionAdd } from "../components/FirmwareManagePanels"
import { useEffect, useState } from 'react'
import { useLogto } from '@logto/react';
const { domain } = require('../config/generalConfig.json')

function FirmwareManage() {
    const { signIn, getAccessToken, isAuthenticated, isLoading } = useLogto()
    const [visb, setVisb] = useState([true, false, false])
    const [accessToken, setAccessToken] = useState(null)
    // Newly Added Firmware Information
    const [addfirmURL, setAddFirmURL] = useState("https://fw.ota.client.netfrc.com/")
    const [addFirmVer, setAddFirmVer] = useState("version")
    const [addFirmSmtID, setAddFirmSmtID] = useState("")
    const [addFirmComment, setAddFirmComment] = useState("")
    const [addFirmJSON, setAddFirmJSON] = useState("")
    const [addFirmName, setAddFirmName] = useState("")
    const [addFirmFile, setAddFirmFile] = useState({name: ""})
    // Firmware Version Addition Information
    const [existingFirmwareList, setExistingFirmwareList] = useState([])
    const [existFirmVers, setExistFirmVers] = useState("")
    const [existFirmComment, setExistFirmComment] = useState("")
    const [existFirmSmtID, setExistFirmSmtID] = useState("")
    const [existFirmFile, setExistFirmFile] = useState(null)
    const [existFirmJSON, setExistFirmJSON] = useState("")
    // To be removed firmware version information 
    const [removeFirmSmtID, setRemoveFirmSmTID] = useState("")

    useEffect(() => {
        async function getAPIToken() {
            if (!isLoading && isAuthenticated && !accessToken) {
                const actk = await getAccessToken('https://api.cendori.entr.netfrc.com/')
                setAccessToken(actk)
            }
            console.log(accessToken)
        }

        getAPIToken()
    }, [accessToken, isAuthenticated, isLoading, visb])

    async function addFirmwarePost(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append('name', addFirmName)
        formData.append('smtid', addFirmSmtID)
        formData.append('version', addFirmVer)
        formData.append('jsonf', addFirmJSON)
        formData.append('file', addFirmFile)
        formData.append('comment', addFirmComment)
        try {
            JSON.parse(addFirmJSON)
        } catch (e) {
            alert("JSON이 알맞지 않습니다. JSON 규격이 맞는지 확인하십시오.")
            return
        }
        fetch('https://api.cendori.entr.netfrc.com/firmware/add', {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            body: formData
        }).then((response) => response.json())
            .then((data) => {
                if (data.success) alert("펌웨어 추가 성공")
                else alert("펌웨어 추가 실패: " + data.error)
            }).catch((error) => alert(error.error))
    }

    async function fetchFirmwareList() {
        fetch('https://api.cendori.entr.netfrc.com/firmware/list', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else {
                    if (!data.authorized) {
                        alert("접근 권한 없음")
                        return
                    }
                    console.log(data)
                    var dataChange = []
                    dataChange[0] = {}
                    dataChange[0].id = data.data[0].id
                    dataChange[0].name = data.data[0].name
                    for (var i = 0; i < data.data.length; i++) {
                        var isExist = false
                        for (var j = 0; j < dataChange.length; j++) {
                            if (data.data[i].id === dataChange[j].id) {
                                isExist = true
                                break
                            }
                        }
                        if (!isExist) {
                            let len = dataChange.length
                            dataChange[len] = {}
                            dataChange[len].id = data.data[i].id
                            dataChange[len].name = data.data[i].name
                        }
                    }
                    setExistingFirmwareList(dataChange)
                    setExistFirmSmtID(dataChange[0].id)
                    setRemoveFirmSmTID(dataChange[0].id)
                }
            }).catch((error) => console.log(error))
    }

    async function submitUpdateFirm(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("smtid", existFirmSmtID)
        formData.append("version", existFirmVers)
        formData.append("file", existFirmFile)
        formData.append("jsonf", existFirmJSON)
        try {
            JSON.parse(existFirmJSON)
        } catch (e) {
            alert("JSON이 알맞지 않습니다. JSON 규격이 맞는지 확인하십시오.")
            return
        }
        formData.append("comment", existFirmComment)
        fetch('https://api.cendori.entr.netfrc.com/firmware/add', {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            body: formData
        }).then((response) => response.json())
            .then((data) => {
                if (data.success) alert("펌웨어 버젼 추가 성공")
                else alert("펌웨어 추가 실패: " + data.error)
            })
            .catch((error) => console.log(error))
    }

    async function submitRemoveFirm(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("smtid", removeFirmSmtID)
        fetch('https://api.cendori.entr.netfrc.com/firmware/delete', {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            body: formData
        }).then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.success) alert("펌웨어 버젼 추가 성공")
                else alert("펌웨어 추가 실패: " + data.error)
            })
            .catch((error) => console.log(error))
    }

    return (
        <div>
            {
                !isLoading ? (
                    isAuthenticated ? (
                        <div className="cendoriwebbody" style={{ minHeight: "100vh" }}>
                            <WeblinkHeader />
                            <div class="cendorimanagefirmcol w-row">
                                <div class="cendorimanagefirmcol1 w-col w-col-3">
                                    { /* Left Side Column with Buttons */
                                        visb[0] ? <div className="cendorifirmwaremanagebtn current">기종 추가</div> : <div class="cendorifirmwaremanagebtn" onClick={() => setVisb([true, false, false])}>기종 추가</div>
                                    }
                                    {
                                        visb[1] ? <div className="cendorifirmwaremanagebtn current">기종 제거</div> : <div class="cendorifirmwaremanagebtn" onClick={() => { setVisb([false, true, false]); fetchFirmwareList() }}>기종 제거</div>
                                    }
                                    {
                                        visb[2] ? <div className="cendorifirmwaremanagebtn current">펌웨어 버젼 추가</div> : <div class="cendorifirmwaremanagebtn" onClick={() => { setVisb([false, false, true]); fetchFirmwareList() }}>펌웨어 버젼 추가</div>
                                    }
                                </div>
                                <div class="cendorimanagefirmcol2 w-col w-col-9">
                                    {
                                        visb[0] ? (<div className="cendorifirmwaremanagepane add" >
                                            <form id="firmware-add-form" name="firmwareAdd" className="form" onSubmit={addFirmwarePost}>
                                                <label for="name" className="field-label">기종 이름</label>
                                                <input className="text-field w-input" maxlength="256" name="name" data-name="name" placeholder="" type="text" id="name" value={addFirmName} onChange={e => setAddFirmName(e.target.value)} />
                                                <label for="smtid" className="field-label">SmartThings Device Profile ID</label>
                                                <input className="text-field w-input" maxlength="256" name="smtid" data-name="smtid" placeholder="" type="text" id="smtid" value={addFirmSmtID} onChange={e => setAddFirmSmtID(e.target.value)} required="" />
                                                <label for="version" className="field-label">최초 버젼</label>
                                                <input className="text-field w-input" maxlength="256" name="version" data-name="version" placeholder="" type="text" id="version" value={addFirmVer} onChange={e => setAddFirmVer(e.target.value)} required="" />
                                                <label for="comment" className="field-label">펌웨어 업로드 코멘트</label>
                                                <input className="text-field w-input" maxlength="256" name="comment" data-name="comment" placeholder="" type="text" id="comment" value={addFirmComment} onChange={e => setAddFirmComment(e.target.value)} required="" />
                                                <label for="file" className="field-label">펌웨어 BIN 파일 </label>
                                                <input type="file" name="file" data-name="file" className="submitfilebtn w-button" placeholder="파일 업로드" onChange={e => { setAddFirmFile(e.target.files[0]) }} />
                                                <div className="div-block">
                                                    <input className="text-field nopadding w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="BIN File URL" type="text" id="field-3" required="" value={addfirmURL + addFirmSmtID + "/" + addFirmVer + "/" + addFirmFile.name } />
                                                    <div className="text-block-3" style={{cursor: "pointer"}} onClick={() =>  navigator.clipboard.writeText(addfirmURL + addFirmSmtID + "/" + addFirmVer + "/" + addFirmFile.name)}>복사</div>
                                                </div>
                                                <label for="jsonf" className="field-label">글로벌 JSON 파일 (version_info.json)</label>
                                                <textarea required="" placeholder="JSON Contents" maxlength="5000" id="jsonf" name="jsonf" data-name="jsonf" value={addFirmJSON} onChange={e => setAddFirmJSON(e.target.value)} className="textarea w-input" />
                                                <input type="submit" className="submit-button w-button" value="기종 추가" />
                                            </form>
                                        </div>) : <div></div>
                                    }
                                    {
                                        visb[1] ? (
                                            <div className="cendorifirmwaremanagepane delete" style={{ display: 'block' }}>
                                                <form id="firmware-delete-form" name="firmware-delete-form" className="form" onSubmit={submitRemoveFirm}>
                                                    <label for="smtid" className="field-label-2">SmartThings Device Profile ID</label>
                                                    <select id="smtid" name="smtid" data-name="smtid" required="" className="select-field w-select" value={removeFirmSmtID} onChange={e => setRemoveFirmSmTID(e.target.value)}>
                                                        {
                                                            existingFirmwareList.map(option => (
                                                                <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <input type="submit" className="submit-button w-button" value="기종 삭제" />
                                                </form>
                                            </div>) : <div></div>
                                    }
                                    {
                                        visb[2] ? (
                                            <div className="cendorifirmwaremanagepane version" style={{ display: 'block' }}>
                                                <form id="firmwareUpdate" name="firmwareUpdate" className="form" onSubmit={submitUpdateFirm}>
                                                    <label for="smtid" class="field-label-2">SmartThings Device Profile ID</label>
                                                    <select id="smtid" name="smtid" data-name="smtid" required="" class="select-field manage w-select" value={existFirmSmtID} onChange={e => setExistFirmSmtID(e.target.value)}>
                                                        {
                                                            existingFirmwareList.map(option => (
                                                                <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <label for="version" class="field-label">업데이트 버젼</label>
                                                    <input class="text-field w-input" maxlength="256" name="version" data-name="version" placeholder="" type="text" id="version" required="" value={existFirmVers} onChange={e => setExistFirmVers(e.target.value)} />
                                                    <label for="comment" class="field-label">업데이트 코멘트</label>
                                                    <input class="text-field w-input" maxlength="256" name="comment" data-name="comment" placeholder="" type="text" id="comment" required="" value={existFirmComment} onChange={e => setExistFirmComment(e.target.value)} />
                                                    <label for="field-4" class="field-label">펌웨어 BIN 파일 </label>
                                                    <input type="file" name="file" id="file" class="submitfilebtn w-button" onChange={e => setExistFirmFile(e.target.files[0])} />
                                                    <div class="div-block">
                                                        <input class="text-field nopadding w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="BIN File URL" type="text" id="field-3" value={addfirmURL + existFirmSmtID + "/" + existFirmVers + "/"} required="" />
                                                        <div class="text-block-3">복사</div>
                                                    </div>
                                                    <label for="field-4" class="field-label">글로벌 JSON 파일 (version_info.json)</label>
                                                    <textarea required="" placeholder="JSON Contents" maxlength="5000" id="field-2" name="field-2" data-name="Field 2" class="textarea w-input" value={existFirmJSON} onChange={e => setExistFirmJSON(e.target.value)}></textarea>
                                                    <input type="submit" class="submit-button w-button" value="펌웨어 버젼 추가" />
                                                </form>
                                            </div>) : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        signIn(`${domain}/callback`)
                    )
                ) : (
                    <div></div>
                )
            }

        </div>
    )
}

export default FirmwareManage