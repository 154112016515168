import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import FirmwareManage from './firmware/Manage';
import FirmwareStats from './firmware/Stats';
import UserManage from './user/Manage';
import ServerDomainManage from './server/Domain';
import CallBack from './callback/CallBack';
import reportWebVitals from './reportWebVitals';
import { LogtoProvider, UserScope } from '@logto/react';

const config = {
  endpoint: 'https://auth.pluxcon.com/',
  appId: '49h55oidfz9yrjpk94uhj',
  resources: ['https://api.cendori.entr.netfrc.com/'],
  scopes: [
    UserScope.Email,
    UserScope.Phone,
    UserScope.CustomData,
    UserScope.Identities,
    UserScope.Organizations,
    UserScope.OrganizationRoles,
  ],
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LogtoProvider config={config}>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<App />} />
        </Route>
        <Route path="firmware/">
          <Route path="manage" element={<FirmwareManage />} />
          <Route path="stats" element={<FirmwareStats />} />
        </Route>
        <Route path="user/">
          <Route path="manage" element={<UserManage />} />
        </Route>
        <Route path="server/">
          <Route path="domain" element={<ServerDomainManage />} />
        </Route>
        <Route path="callback" element={<CallBack />} />
        { /* No Page Exists, 404 */}
        <Route path="*" />
      </Routes>
    </BrowserRouter>
  </LogtoProvider>
);

reportWebVitals();