function UserAdd() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="user-add-form" name="user-add-form" className="form">
                <label for="Device-Name-2" className="field-label">실명</label>
                <input className="text-field w-input" maxlength="256" name="Device-Name-2" data-name="Device Name 2" placeholder="" type="text" id="Device-Name-2" />
                <label for="Device-Profile-ID-3" className="field-label">소속사</label>
                <input className="text-field w-input" maxlength="256" name="Device-Profile-ID-3" data-name="Device Profile ID 3" placeholder="" type="text" id="Device-Profile-ID-3" required="" />
                <label for="Device-Profile-ID-4" className="field-label">소속 부서</label>
                <input className="text-field w-input" maxlength="256" name="Device-Profile-ID-3" data-name="Device Profile ID 3" placeholder="" type="text" id="Device-Profile-ID-3" required="" />
                <label for="Device-Profile-ID-4" className="field-label">이메일 </label>
                <input className="text-field w-input" maxlength="256" name="Device-Profile-ID-3" data-name="Device Profile ID 3" placeholder="" type="text" id="Device-Profile-ID-3" required="" />
                <label for="Device-Profile-ID-3" className="field-label">비밀번호</label>
                <input className="text-field w-input" maxlength="256" name="Device-Profile-ID-3" data-name="Device Profile ID 3" placeholder="" type="password" id="Device-Profile-ID-3" required="" />
                <label for="field-4" className="field-label">권한 리스트 </label><select id="field-4" name="field-4" data-name="Field 4" className="select-field w-select">
                    <option value="">Select one...</option>
                    <option value="First">First choice</option>
                    <option value="Second">Second choice</option>
                    <option value="Third">Third choice</option>
                </select>
                <input type="submit" data-wait="Please wait..." className="submit-button w-button" value="유저 추가" />
            </form>
        </div>
    )
}

function UserAuthManage() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="user-auth-manage" name="user-auth-manage" className="form">
                <label for="ProfileID-4" className="field-label-2">해당 유저 </label>
                <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select>
                <label for="ProfileID-4" className="field-label-2">변경될 권한 리스트 </label>
                <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select>
                <input type="submit" data-wait="Please wait..." className="submit-button w-button" value="유저 권한 변경" />
            </form>
        </div>
    )
}

function UserDel() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="user-del-form" name="user-del-form" className="form">
                <label for="ProfileID-4" className="field-label-2">해당 유저</label>
                <select id="ProfileID-2" name="ProfileID-2" data-name="Profile ID 2" required="" className="select-field w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select><input type="submit" data-wait="Please wait..." className="submit-button w-button" value="유저 삭제" />
            </form>
        </div>
    )
}

function AuthListAdd() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="authlist-add-form" name="authlist-add-form" className="form">
                <label for="field-5" className="field-label">권한 리스트명 </label>
                <input className="text-field w-input" maxlength="256" name="Device-Profile-ID-2" data-name="Device Profile ID 2" placeholder="" type="text" id="Device-Profile-ID-2" required="" />
                <label for="field-5" className="field-label">해당 권한</label>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <a href="#" className="submitfilebtn yespadding w-button">권한 추가</a>
                <input type="submit" data-wait="Please wait..." className="submit-button w-button" value="권한 리스트 추가" />
            </form>
        </div>
    )
}

function AuthListManage() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="authlist-manage-form" name="authlist-manage-form" className="form">
                <label for="ProfileID-4" className="field-label-2">권한 리스트명</label>
                <select id="ProfileID-2" name="ProfileID-2" data-name="Profile ID 2" required="" className="select-field manage w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select>
                <label for="field-5" className="field-label">해당되는 권한</label>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <div className="authdropdownflexdiv">
                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" className="select-field nopadding actuallynone w-select">
                        <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                        <option value="First">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                        <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                    </select>
                    <div className="text-block-4">삭제</div>
                </div>
                <a href="#" className="submitfilebtn yespadding w-button">권한 추가</a>
                <input type="submit" data-wait="Please wait..." className="submit-button w-button" value="권한 리스트 수정" />
            </form>
        </div>
    )
}

function AuthListDel() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="authlist-del-form" name="authlist-del-form" className="form">
                <label for="ProfileID-4" className="field-label-2">권한 리스트명</label>
                <select id="ProfileID-2" name="ProfileID-2" data-name="Profile ID 2" required="" className="select-field manage w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select>
                <label for="field-5" className="field-label">대체 권한 리스트</label>
                <select id="ProfileID-2" name="ProfileID-2" data-name="Profile ID 2" required="" className="select-field w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select>
                <input type="submit" data-wait="Please wait..." className="submit-button w-button" value="권한 리스트 삭제" />
            </form>
        </div>
    )
}

function RunLogs() {
    return (
        <div className="cendorifirmwaremanagepane useradd">
            <form id="log-search-form" name="log-search-form" className="form">
                <label for="ProfileID-4" className="field-label-2">활동 유저 필터링</label>
                <select id="ProfileID-2" name="ProfileID-2" data-name="Profile ID 2" required="" className="select-field manage w-select">
                    <option value="2390j901234jkflasdfn">센도리 (2390j901234jkflasdfn)</option>
                    <option value="First">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Second">센도리 (2390j901234jkflasdfn)</option>
                    <option value="Third">센도리 (2390j901234jkflasdfn)</option>
                </select>
                <input type="submit" className="submitfilebtn userfilter w-button" value="로그 확인" />
            </form>
            <div className="cendorilogdiv main">
                <div className="cendoriloglistdiv">
                    <div id="w-node-_03782789-a437-f1cd-30e5-79a4e5b209e8-c2738268" className="cendorilogcentraldiv">
                        <div>유저</div>
                    </div>
                    <div id="w-node-_575e99d4-3675-c6ea-bf27-9efa1f024490-c2738268" className="cendorilogcentraldiv">
                        <div>유저 활동</div>
                    </div>
                    <div id="w-node-_27668aac-cf9f-09a8-9ef5-a28d733733f0-c2738268" className="cendorilogcentraldiv">
                        <div>아이피 주소</div>
                    </div>
                    <div id="w-node-_2971d793-2d9d-f5f1-2780-69b52f36ea8f-c2738268" className="cendorilogcentraldiv">
                        <div>접근 시간대</div>
                    </div>
                </div>
            </div>
            <div className="cendorilogdiv">
                <div className="cendoriloglistdiv">
                    <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10be9-c2738268" className="cendorilogcentraldiv">
                        <div>정광일 - kichung@cendori.com</div>
                    </div>
                    <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10bec-c2738268" className="cendorilogcentraldiv">
                        <div>유저 권한페이지 - 접근실패</div>
                    </div>
                    <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10bef-c2738268" className="cendorilogcentraldiv">
                        <div>195.128.0.2</div>
                    </div>
                    <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10bf2-c2738268" className="cendorilogcentraldiv">
                        <div>2023/07/28 13:58:39 PM KST</div>
                    </div>
                </div>
            </div>
            <div className="cendorilogdiv">
                <div className="cendoriloglistdiv">
                    <div id="w-node-_0fb0f8f4-2ff7-3b25-8f61-6a10e9daae40-c2738268" className="cendorilogcentraldiv">
                        <div>정광일 - kichung@cendori.com</div>
                    </div>
                    <div id="w-node-_0fb0f8f4-2ff7-3b25-8f61-6a10e9daae43-c2738268" className="cendorilogcentraldiv">
                        <div>유저 권한페이지 - 접근실패</div>
                    </div>
                    <div id="w-node-_0fb0f8f4-2ff7-3b25-8f61-6a10e9daae46-c2738268" className="cendorilogcentraldiv">
                        <div>195.128.0.2</div>
                    </div>
                    <div id="w-node-_0fb0f8f4-2ff7-3b25-8f61-6a10e9daae49-c2738268" className="cendorilogcentraldiv">
                        <div>2023/07/28 13:58:39 PM KST</div>
                    </div>
                </div>
            </div>
            <div className="cendorilogdiv">
                <div className="cendoriloglistdiv">
                    <div id="w-node-_4a67477a-9dbe-4aca-473f-b39f29a077e6-c2738268" className="cendorilogcentraldiv">
                        <div>정광일 - kichung@cendori.com</div>
                    </div>
                    <div id="w-node-_4a67477a-9dbe-4aca-473f-b39f29a077e9-c2738268" className="cendorilogcentraldiv">
                        <div>유저 권한페이지 - 접근실패</div>
                    </div>
                    <div id="w-node-_4a67477a-9dbe-4aca-473f-b39f29a077ec-c2738268" className="cendorilogcentraldiv">
                        <div>195.128.0.2</div>
                    </div>
                    <div id="w-node-_4a67477a-9dbe-4aca-473f-b39f29a077ef-c2738268" className="cendorilogcentraldiv">
                        <div>2023/07/28 13:58:39 PM KST</div>
                    </div>
                </div>
            </div>
            <div className="cendorilogdiv">
                <div className="cendoriloglistdiv">
                    <div id="w-node-_04c7be9f-cdf4-c8a3-2471-b1d4b3cb07b1-c2738268" className="cendorilogcentraldiv">
                        <div>정광일 - kichung@cendori.com</div>
                    </div>
                    <div id="w-node-_04c7be9f-cdf4-c8a3-2471-b1d4b3cb07b4-c2738268" className="cendorilogcentraldiv">
                        <div>유저 권한페이지 - 접근실패</div>
                    </div>
                    <div id="w-node-_04c7be9f-cdf4-c8a3-2471-b1d4b3cb07b7-c2738268" className="cendorilogcentraldiv">
                        <div>195.128.0.2</div>
                    </div>
                    <div id="w-node-_04c7be9f-cdf4-c8a3-2471-b1d4b3cb07ba-c2738268" className="cendorilogcentraldiv">
                        <div>2023/07/28 13:58:39 PM KST</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    UserAdd,
    UserAuthManage,
    UserDel,
    AuthListAdd,
    AuthListManage,
    AuthListDel,
    RunLogs
}