import WeblinkHeader from "../components/WebLinkHeader"
import { UserAdd, UserAuthManage, UserDel, AuthListAdd, AuthListManage, AuthListDel, RunLogs } from "../components/UserManagePanels"
import { useEffect, useState } from "react"
import { useLogto } from '@logto/react';

function UserManage() {
    var [visb, setVisb] = useState([true, false, false, false, false, false, false])
    const { signIn, isAuthenticated, isLoading } = useLogto();

    useEffect(() => {

    }, [visb, isAuthenticated, isLoading])

    return (
        <div>
            {
                !isLoading ? (
                    isAuthenticated ? (
                        <div className="cendoriwebbody" style={{ minHeight: "100vh" }}>
                            <WeblinkHeader />
                            <div className="cendorimanagefirmcol w-row">
                                <div class="cendorimanagefirmcol1 w-col w-col-3">
                                    { /* LeftSide Current Buttons */}
                                    {
                                        visb[0] ? <div className="cendorifirmwaremanagebtn current">유저 추가</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([true, false, false, false, false, false, false])}>유저 추가</div>
                                    }
                                    {
                                        visb[1] ? <div className="cendorifirmwaremanagebtn current">유저 권한 관리</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, true, false, false, false, false, false])}>유저 권한 관리</div>
                                    }
                                    {
                                        visb[2] ? <div className="cendorifirmwaremanagebtn current">유저 삭제</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, true, false, false, false, false])}>유저 삭제</div>
                                    }
                                    {
                                        visb[3] ? <div className="cendorifirmwaremanagebtn current">권한 리스트 추가</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, true, false, false, false])}>권한 리스트 추가</div>
                                    }
                                    {
                                        visb[4] ? <div className="cendorifirmwaremanagebtn current">권한 리스트 관리</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, false, true, false, false])}>권한 리스트 관리</div>
                                    }
                                    {
                                        visb[5] ? <div className="cendorifirmwaremanagebtn current">권한 리스트 삭제</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, false, false, true, false])}>권한 리스트 삭제</div>
                                    }
                                    {
                                        visb[6] ? <div className="cendorifirmwaremanagebtn current">활동 로그</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, false, false, false, true])}>활동 로그</div>
                                    }
                                </div>
                                <div class="cendorimanagefirmcol2 w-col w-col-9">
                                    {
                                        visb[0] ? <UserAdd /> : <div></div>
                                    }
                                    {
                                        visb[1] ? <UserAuthManage /> : <div></div>
                                    }
                                    {
                                        visb[2] ? <UserDel /> : <div></div>
                                    }
                                    {
                                        visb[3] ? <AuthListAdd /> : <div></div>
                                    }
                                    {
                                        visb[4] ? <AuthListManage /> : <div></div>
                                    }
                                    {
                                        visb[5] ? <AuthListDel /> : <div></div>
                                    }
                                    {
                                        visb[6] ? <RunLogs /> : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        signIn('http://localhost:3000/callback')
                    )
                ) : (
                    <div></div>
                )
            }
        </div>
    )
}

export default UserManage